<template>
  <div>
    <es-title title="Emolumentos">
        <div slot="left">
          <es-datepicker v-if="tab == 0" v-model="data" type="button" @selected="get_resumo()"></es-datepicker>
          <a v-if="tab != 0" class="button is-warnning" @click="set_tab(0)">Voltar</a>
        </div>
        <div slot="right">
          <div class="button is-link" @click="getModalTrazerCustas()"><icon name="download"></icon> Trazer Emolumentos</div>
          <div class="button is-warning" @click="getCustasSemDocumento()"><span class="tag is-danger is-rounded">{{ totalSemDoc }}</span>&nbsp; Sem Documento</div>
          <div class="button is-primary" @click="getPostergados()">Postergadas</div>
          <!-- <div class="button is-success" @click="load()"><icon name="download"></icon></div> -->
        </div>
    </es-title>
    <es-filter :config="fPesquisa"></es-filter>
    <br>
    <div class="tabs is-boxed">
      <ul>
        <li v-for="(aba, idx) in abas " :class="{'is-active': tabMovimento == idx}" @click="setTab(idx)">
          <a>
            <span>{{ aba }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="tabMovimento == 1">
      <div v-if="tab == 0">
        <table class="table is-bordered is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" rowspan="2" width="35%">Apresentante</th>
              <th class="has-text-centered" rowspan="2" width="5%">UF</th>
              <th class="has-text-centered" colspan="6" width="30%">Retorno</th>
              <!-- <th class="has-text-centered" colspan="5" width="25%">Confirmação</th> -->
              <th class="has-text-centered" rowspan="2" width="15%">Total</th>
              <th class="has-text-centered" rowspan="2" width="5%">Ação <hr>
              <input type="checkbox" name="todos" value="1" v-model="adiarTudo" @click="adiarTodos()"><br>
              </th>
            </tr>
            <tr>
              <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">Pagos</th>
              <th class="has-text-centered">Gerados</th>
              <th class="has-text-centered">Desconto</th>
              <th class="has-text-centered">Total Bruto</th>
              <th class="has-text-centered">Total PagFor</th>
              <!-- <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">P</th>
              <th class="has-text-centered">G</th>
              <th class="has-text-centered">Total</th>
              <th class="has-text-centered">PagFor</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx, key) in resumo">
              <tr v-if="item.grupo" class="group" :key="key">
                <td colspan="2">{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td colspan="4" class="has-text-right" style="color: #0087ff;">{{ item.retorno_total_desconto | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td colspan="4" class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td> -->
                <!-- <td colspan="1" class="has-text-right">{{ item.confirmacao_total | currency }}</td> -->
                <td class="has-text-right">{{ item.confirmacao_total + item.retorno_total | currency }}</td>
                <td class="has-text-centered">...</td>
              </tr>
              <tr v-else :key="key" :class="{'lineAmarelo':item.checado == true}" >
                <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td class="has-text-centered">{{ item.uf }}</td>
                <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'R')">{{ item.retorno_qtd | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_gerados | nozero }}</td>
                <td class="has-text-right" :class="{'linedesconto':item.retorno_total_desconto > 0}">{{ item.retorno_total_desconto | currency }}</td>
                <td class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td class="has-text-right" style="background: rgb(151, 219, 187) none repeat scroll 0% 0%;">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td> -->
                <!-- <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.confirmacao_total | currency }}</td>
                <td class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td> -->
                <td class="has-text-right">{{ item.total | currency }}</td>
                <td class="has-text-centered">
                  <input type="checkbox" :id=idx v-model="item.checado">
                </td>
              </tr>
          </template>
        </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                  <div>
                    <button class="button" @click="gerarPagamentosDia()">Gerar</button>
                    <button class="button is-success is-pulled-right" @click="regerarPagamentosDia()">Regerar</button>
                    <hr/>
                    <button class="button is-info" @click="relatorioPdf()">Rel. Banco PDF</button>
                    <button class="button is-info" @click="relatorioEspelhoPdf()">Rel. Espelho PDF</button>
                    <button class="button is-info" @click="relatorioDescontoPdf()">Rel. Descontos PDF</button>
                    <button class="button is-info is-pulled-right" @click="relatorioXlsx()">Rel. XLS</button>
                  </div>
              </td>
              <td colspan="4" class="has-text-right" style="color: #0087ff;">{{ total_desconto | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno_desc | currency }}</td>
              <!-- <td colspan="4" class="has-text-right">{{ total_confirmacao | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_confirmacao_desc | currency }}</td> -->
              <td class="has-text-right">{{ (total_retorno + total_confirmacao) | currency}}</td>
              <td class="has-text-right">
                <a class="button is-warning" @click="addAdiar">Adiar</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else-if="tab == 1">
        <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
       <es-table :config="tblCustas" :rows="custas" @rowclick="nova_conta">
          <div slot="buttons">
            <!-- <div class="button" @click="gerar_pagamentos()">Gerar</div> -->
            <div v-if="data === data_atual" class="button" @click="adiar()">Adiar</div>
            <div v-if="data !== data_atual" class="button" @click="trazer()">Trazer</div>
            <div class="button" @click="restaurar()">Restaurar</div>
            <div class="button" @click="postergar()">Postergar</div>
            <button v-if="buttonrelPesquisa" class="button is-info" @click="relatorioPesquisaUFPdf()">Rel. Pesquisa</button>
            <button v-if="buttonrelUfCusta" class="button is-info" @click="relatorioUFPdf()">Rel. UF Banco PDF</button>
            <button v-if="buttonrelUfCusta" class="button is-danger" @click="modalDeletarCustas()">Deletar</button>
          </div>
        </es-table>
      </div>
      <div v-else>
        <div style="max-height: 550px;overflow-y: scroll;" class="limitarTable">
          <es-table :config="tblPostegadas" :rows="postergados">
            <div slot="buttons">
              <div class="button" @click="trazerPostergados()">Trazer</div>
              <div class="button" @click="excluir()">Excluir</div>
            </div>
          </es-table>
        </div>
      </div>
    </div>
    <div v-else-if="tabMovimento == 3">
      <div v-if="tab == 0">
        <table class="table is-bordered is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" rowspan="2" width="35%">Apresentante</th>
              <th class="has-text-centered" rowspan="2" width="5%">UF</th>
              <th class="has-text-centered" colspan="6" width="30%">Retorno</th>
              <!-- <th class="has-text-centered" colspan="5" width="25%">Confirmação</th> -->
              <th class="has-text-centered" rowspan="2" width="15%">Total</th>
              <th class="has-text-centered" rowspan="2" width="5%">Ação <hr>
              <input type="checkbox" name="todosE" value="3" v-model="adiarTudo" @click="adiarTodosE()"> </th>
            </tr>
            <tr>
              <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">Pagos</th>
              <th class="has-text-centered">Gerados</th>
              <th class="has-text-centered">Desconto</th>
              <th class="has-text-centered">Total Bruto</th>
              <th class="has-text-centered">Total PagFor</th>
              <!-- <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">P</th>
              <th class="has-text-centered">G</th>
              <th class="has-text-centered">Total</th>
              <th class="has-text-centered">PagFor</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx, key) in resumo_empresas">
              <tr v-if="item.grupo" class="group" :key="key">
                <td colspan="2">{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td colspan="4" class="has-text-right" style="color: #0087ff;">{{ item.retorno_total_desconto | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td colspan="4" class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.confirmacao_total | currency }}</td> -->
                <td class="has-text-right">{{ item.confirmacao_total + item.retorno_total | currency }}</td>
                <td class="has-text-centered">...</td>
              </tr>
              <tr v-else :key="key" :class="{'lineAmarelo':item.checado == true}">
                <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td class="has-text-centered">{{ item.uf }}</td>
                <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'R')">{{ item.retorno_qtd | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_gerados | nozero }}</td>
                <td class="has-text-right" :class="{'linedesconto':item.retorno_total_desconto > 0}">{{ item.retorno_total_desconto | currency }}</td>
                <td class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td class="has-text-right" style="background: rgb(151, 219, 187) none repeat scroll 0% 0%;">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td> -->
                <!-- <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.confirmacao_total | currency }}</td>
                <td class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td> -->
                <td class="has-text-right">{{ item.total | currency }}</td>
                <td class="has-text-centered">
                  <input type="checkbox" :id=idx v-model="item.checado">
                </td>
              </tr>
          </template>
        </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <button class="button" @click="gerarPagamentosDia()">Gerar</button>
                <button class="button is-info" @click="relatorioPdf()">Rel. Empresas PDF</button>
                <button class="button is-info" @click="relatorioEspelhoPdf()">Rel. Espelho PDF</button>
                <button class="button is-success is-pulled-right" @click="regerarPagamentosDia()">Regerar</button>
              </td>
              <td colspan="4" class="has-text-right">{{ total_retorno_emp_desconto | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno_emp | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno_emp_desc | currency }}</td>
              <!-- <td colspan="5" class="has-text-right">{{ total_confirmacao_emp | currency }}</td> -->
              <!-- td colspan="1" class="has-text-right">{{ total_confirmacao_emp_desc | currency }}</td -->
              <td class="has-text-right">{{ (total_retorno_emp + total_confirmacao_emp) | currency}}</td>
              <td class="has-text-right">
                <a class="button is-warning" @click="addAdiar">Adiar</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else-if="tab == 1">
        <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
       <es-table :config="tblCustas" :rows="custas" @rowclick="nova_conta">
          <div slot="buttons">
            <!-- <div class="button" @click="gerar_pagamentos()">Gerar</div> -->
            <div v-if="data === data_atual" class="button" @click="adiar()">Adiar</div>
            <div v-if="data !== data_atual" class="button" @click="trazer()">Trazer</div>
            <div class="button" @click="restaurar()">Restaurar</div>
            <div class="button" @click="postergar()">Postergar</div>
            <button v-if="buttonrelPesquisa" class="button is-info" @click="relatorioPesquisaUFPdf()">Rel. Pesquisa</button>
            <button v-if="buttonrelUfCusta" class="button is-info" @click="relatorioUFPdf()">Rel. UF Empresas PDF</button>
          </div>
        </es-table>
      </div>
      <div v-else>
        <div style="max-height: 550px;overflow-y: scroll;" class="limitarTable">
          <es-table :config="tblPostegadas" :rows="postergados">
            <div slot="buttons">
              <div class="button" @click="trazerPostergados()">Trazer</div>
              <div class="button" @click="excluir()">Excluir</div>
            </div>
          </es-table>
        </div>
      </div>
    </div>
    <div v-else-if="tabMovimento == 4">
      <div v-if="tab == 0">
        <table class="table is-bordered is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" rowspan="2" width="35%">Apresentante</th>
              <th class="has-text-centered" rowspan="2" width="5%">UF</th>
              <th class="has-text-centered" colspan="4" width="25%">Retorno</th>
              <!-- <th class="has-text-centered" colspan="4" width="25%">Confirmação</th> -->
              <th class="has-text-centered" rowspan="2" width="15%">Total</th>
              <th class="has-text-centered" rowspan="2" width="5%">Ação <hr>
              <input type="checkbox" name="todosC" value="4" v-model="adiarTudo" @click="adiarTodosC()"> </th>
            </tr>
            <tr>
              <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">Pagos</th>
              <th class="has-text-centered">Gerados</th>
              <th class="has-text-centered">Total</th>
              <!-- <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">P</th>
              <th class="has-text-centered">G</th>
              <th class="has-text-centered">Total</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx, key) in resumo_empresas_canceladas">
              <tr v-if="item.grupo" class="group" :key="key">
                <td colspan="2">{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td colspan="4" class="has-text-right">{{ item.retorno_total | currency }}</td>
                <!-- <td colspan="4" class="has-text-right">{{ item.confirmacao_total | currency }}</td> -->
                <td class="has-text-right">{{ item.confirmacao_total + item.retorno_total | currency }}</td>
                <td class="has-text-centered">...</td>
              </tr>
              <tr v-else :key="key" :class="{'lineAmarelo':item.checado == true}">
                <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td class="has-text-centered">{{ item.uf }}</td>
                <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'R')">{{ item.retorno_qtd | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.retorno_total | currency }}</td>
                <!-- <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td> -->
                <!-- <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.confirmacao_total | currency }}</td> -->
                <td class="has-text-right">{{ item.total | currency }}</td>
                <td class="has-text-centered">
                  <input type="checkbox" :id=idx v-model="item.checado">
                </td>
              </tr>
          </template>
        </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <button class="button" @click="gerarPagamentosDia()">Gerar</button>
                <button class="button is-info" @click="relatorioPdf()">Rel. Cancelamento PDF</button>
                <button class="button is-info" @click="relatorioEspelhoPdf()">Rel. Espelho</button>
                <button class="button is-success is-pulled-right" @click="regerarPagamentosDia()">Regerar</button>
              </td>
              <td colspan="4" class="has-text-right">{{ total_retorno_emp_can | currency }}</td>
              <td colspan="4" class="has-text-right">{{ total_confirmacao_emp_can | currency }}</td>
              <td class="has-text-right">{{ (total_retorno_emp_can + total_confirmacao_emp_can) | currency}}</td>
              <td class="has-text-right">
                <a class="button is-warning" @click="addAdiar">Adiar</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else-if="tab == 1">
        <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
       <es-table :config="tblCustas" :rows="custas" @rowclick="nova_conta">
          <div slot="buttons">
            <!-- <div class="button" @click="gerar_pagamentos()">Gerar</div> -->
            <div v-if="data === data_atual" class="button" @click="adiar()">Adiar</div>
            <div v-if="data !== data_atual" class="button" @click="trazer()">Trazer</div>
            <div class="button" @click="restaurar()">Restaurar</div>
            <div class="button" @click="postergar()">Postergar</div>
            <button v-if="buttonrelPesquisa" class="button is-info" @click="relatorioPesquisaUFPdf()">Rel. Pesquisa</button>
            <button v-if="buttonrelUfCusta" class="button is-info" @click="relatorioUFPdf()">Rel. UF Empresas Canceladas PDF</button>
          </div>
        </es-table>
      </div>
      <div v-else>
        <div style="max-height: 550px;overflow-y: scroll;" class="limitarTable">
          <es-table :config="tblPostegadas" :rows="postergados">
            <div slot="buttons">
              <div class="button" @click="trazerPostergados()">Trazer</div>
              <div class="button" @click="excluir()">Excluir</div>
            </div>
          </es-table>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="tab == 0">
        <table class="table is-bordered is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th class="has-text-centered" rowspan="2" width="35%">Apresentante</th>
              <th class="has-text-centered" rowspan="2" width="5%">UF</th>
              <th class="has-text-centered" colspan="6" width="30%">Retorno</th>
              <!-- <th class="has-text-centered" colspan="5" width="25%">Confirmação</th> -->
              <th class="has-text-centered" rowspan="2" width="15%">Total</th>
              <th class="has-text-centered" rowspan="2" width="5%">Ação <hr>
              <input type="checkbox" name="todosS" value="2" v-model="adiarTudo" @click="adiarTodosS()">
              </th>
            </tr>
            <tr>
              <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">Pagos</th>
              <th class="has-text-centered">Gerados</th>
              <th class="has-text-centered">Desconto</th>
              <th class="has-text-centered">Total Bruto</th>
              <th class="has-text-centered">Total PagFor</th>
              <!-- <th class="has-text-centered">Qtd.</th>
              <th class="has-text-centered">P</th>
              <th class="has-text-centered">G</th>
              <th class="has-text-centered">Total</th>
              <th class="has-text-centered">PagFor</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx, key) in resumo_santander">
              <tr v-if="item.grupo" class="group" :key="key" v-bind:id="item.apresentante">
                <td colspan="2">{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td colspan="4" class="has-text-right">{{ item.retorno_total_desconto | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td colspan="4" class="has-text-right">{{ item.confirmacao_total | currency }}</td>
                <td colspan="1" class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td> -->
                <td class="has-text-right">{{ item.confirmacao_total + item.retorno_total | currency }}</td>
                <td class="has-text-centered">...</td>
              </tr>
              <tr v-else :key="key" v-bind:id="item.apresentante" :class="{'lineAmarelo':item.checado == true}">
                <td>{{ item.apresentante_codigo }}-{{ item.apresentante | capitalize}}</td>
                <td class="has-text-centered">{{ item.uf }}</td>
                <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'R')">{{ item.retorno_qtd | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.retorno_gerados | nozero }}</td>
                <td colspan="1" class="has-text-right" :class="{'linedesconto':item.retorno_total_desconto > 0}" >{{ item.retorno_total_desconto | currency }}</td>
                <td class="has-text-right">{{ item.retorno_total | currency }}</td>
                <td class="has-text-right"  style="background: rgb(151, 219, 187) none repeat scroll 0% 0%;">{{ item.retorno_total_desc | currency }}</td>
                <!-- <td class="has-text-centered clickable" @click="get_custas_apresentante_uf(idx, 'C')">{{ item.confirmacao_qtd | nozero }}</td> -->
                <!-- <td class="has-text-centered">{{ item.confirmacao_pendente | nozero }}</td>
                <td class="has-text-centered">{{ item.confirmacao_gerados | nozero }}</td>
                <td class="has-text-right">{{ item.confirmacao_total | currency }}</td>
                <td class="has-text-right">{{ item.confirmacao_total_desc | currency }}</td> -->
                <td class="has-text-right">{{ item.total | currency }}</td>
                <td class="has-text-centered">
                  <input type="checkbox" :id=idx v-model="item.checado">
                </td>
              </tr>
          </template>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <button class="button" @click="gerarPagamentosDia()">Gerar</button>
                <button class="button is-info" @click="relatorioPdf()">Rel. Santander PDF</button>
                <button class="button is-info" @click="relatorioEspelhoPdf()">Rel. Espelho PDF</button>
                <button class="button is-info" @click="relatorioDescontoPdf()">Rel. Descontos PDF</button>
                <button class="button is-success is-pulled-right" @click="regerarPagamentosDia()">Regerar</button>
              </td>
              <td colspan="4" class="has-text-right">{{ total_retorno_san_desconto | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno_san | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_retorno_san_desc | currency }}</td>
              <!-- <td colspan="4" class="has-text-right">{{ total_confirmacao_san | currency }}</td>
              <td colspan="1" class="has-text-right">{{ total_confirmacao_san_desc | currency }}</td> -->
              <td class="has-text-right">{{ (total_retorno_san + total_confirmacao_san) | currency}}</td>
              <td class="has-text-right">
                <a class="button is-warning" @click="addAdiar">Adiar</a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-else-if="tab == 1">
        <h5 class="title is-5 es-primary"><span><icon name="chevron-right" style="color: #ccc"></icon> {{ subtitulo }}</span></h5>
       <es-table :config="tblCustas" :rows="custas" @rowclick="nova_conta">
          <div slot="buttons">
            <!-- <div class="button" @click="gerar_pagamentos()">Gerar</div> -->
            <div v-if="data === data_atual" class="button" @click="adiar()">Adiar</div>
            <div v-if="data !== data_atual" class="button" @click="trazer()">Trazer</div>
            <div class="button" @click="restaurar()">Restaurar</div>
            <div class="button" @click="postergar()">Postergar</div>
            <button v-if="buttonrelPesquisa" class="button is-info" @click="relatorioPesquisaUFPdf()">Rel. Pesquisa</button>
            <button v-if="buttonrelUfCusta" class="button is-info" @click="relatorioUFPdf()">Rel. UF Santander PDF</button>
          </div>
        </es-table>
      </div>
      <div v-else>
        <div style="max-height: 550px;overflow-y: scroll;" class="limitarTable">
          <es-table :config="tblPostegadas" :rows="postergados">
            <div slot="buttons">
              <div class="button" @click="trazerPostergados()">Trazer</div>
              <div class="button" @click="excluir()">Excluir</div>
            </div>
          </es-table>
        </div>
      </div>
    </div>
    <es-modal title="Emolumentos Encontrados" :config="modalEmolumentosEncontrados">
      <div class="columns">
        <div class="column">
          Apresentante:  {{ this.dadosEmolumentosEncontrados.nome_apresentante }}
        </div>
        <div class="column">
          UF: {{ this.dadosEmolumentosEncontrados.uf }}
        </div>
        <div class="column">
          Data de retorno:  {{ this.dadosEmolumentosEncontrados.data_custa }}
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <article class="panel is-info"> 
            <p class="button is-info" style="width: 100%;">Total: {{ this.dadosEmolumentosEncontrados.total }} </p>
          </article>
        </div>
        <div class="column">
          <article class="panel is-danger">
            <p class="button is-danger" style="width: 100%;">Total Não Pagos: {{ this.dadosEmolumentosEncontrados.total_novos }}</p> 
          </article> 
        </div>
        <div class="column">
          <article class="panel is-success">
            <p class="button is-success" style="width: 100%;">Total Pagos: {{ this.dadosEmolumentosEncontrados.total_pagos }}</p>
          </article>
        </div>
      </div>
      
      <div class="tabs is-boxed">
        <ul>
          <li v-for="(abaEmolumento, idx) in abasEmolumento " :class="{'is-active': tabMovimentoEmolumento == idx}" @click="setTabEmolumentos(idx)">
            <a>
              <span>{{ abaEmolumento }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div v-show="tabMovimentoEmolumento == 1">
        <es-table :config="tblEncontradosEmolumentosNovos" :rows="dadosEmolumentosEncontrados.emolumentos_novos"></es-table>
      </div>
      <div v-show="tabMovimentoEmolumento == 2">
        <es-table :config="tblEncontradosEmolumentosPagos" :rows="dadosEmolumentosEncontrados.emolumentos_pagos"></es-table>
      </div>
    </es-modal>
    <es-modal title="Trazer Emolumentos" :config="modalTrazerCustas">
      <es-form :config="frmTrazerCustas"></es-form>
    </es-modal>
    <es-modal title="Add Conta" :config="modal">
      <es-form v-if="tabFavorecido == 0" :config="frmCustas"></es-form>
      <es-table v-else-if="tabFavorecido==1" :config="tblFavorecido" :rows="favorecidos" @rowclick="setFavorecido"></es-table>
      <hr>
      <p>Histório de pagamento <b>( 2 ÚLTIMOS PAGAMENTOS )</b></p>
      <es-table :config="tabelaHistoricos" :rows="historicos" ></es-table>
    </es-modal>
    <es-modal title="Cadastrar Follow Up" :config="modalFollowUp" >
      <es-form :config="frmFollowUp"></es-form>
      <div style="margin-top: 10px;">
        <es-table :config="tblFollowUp" :rows="followup">
        </es-table>
      </div>
    </es-modal>
    <es-modal title="Cadastrar Follow Up - Informe o motivo pelo qual vai deletar!" :config="modalFollowUpDeletar" >
      <es-form :config="frmFollowUpDeletar"></es-form>
      <div style="margin-top: 10px;">
      </div>
    </es-modal>
    <es-modal title="Títulos da custa" :config="modalDetalhes" >
      <div style="margin-top: 10px;">
        <es-table :config="tblDetalhes" :rows="detalhes">
        </es-table>
      </div>
    </es-modal>
    <es-loading :show="loading"></es-loading>
  </div>

</template>

<script>
import axios from 'axios'
import utils from '@/utils'

export default {
  data () {
    return {
      data: '',
      data_atual: '',
      resumo: [],
      historicos: [],
      resumo_santander: [],
      resumo_empresas: [],
      resumo_empresas_canceladas: [],
      tipoPG: '1',
      adiarTudo: false,
      totalSemDoc: '0',
      custas: [],
      followup: [],
      detalhes: [],
      favorecidos: [],
      tabMovimento: 1,
      postergados: [],
      lista: {},
      subtitulo: '',
      selected: {},
      buttonrelPesquisa: false,
      buttonrelUfCusta: false,
      filter: {
        apresentante: '',
        uf: '',
        tipo_arquivo: ''
      },
      dadosEmolumentosEncontrados: {},
      abas: {
        1: 'Pagamento Bradesco',
        2: 'Pagamento Santander',
        3: 'Pagamento Empresas',
        4: 'Pagamento - Custas de Cancelamento'
      },
      abasEmolumento: {
        1: 'Emolumentos Não Pagos',
        2: 'Emolumentos Pagos'
      },
      tabMovimentoEmolumento: 1,
      tab: 0,
      tabFavorecido: 0,
      token: '',
      tblEncontradosEmolumentosNovos: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_custa', label: 'Data Retorno', type: 'date'},
          {name: 'valor_custas', label: 'Vl. Custa'},
          {name: 'valor_demais_despesas', label: 'Vl. Demais Despesas'},
          {name: 'valor_custas_distribuicao', label: 'Vl. Distribuição'}
        ],
        key: 'id'
      },
      tblEncontradosEmolumentosPagos: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_custa', label: 'Data Retorno', type: 'date'},
          {name: 'data_pagamento', label: 'Data Pagamento', type: 'date'},
          {name: 'valor_custas', label: 'Vl. Custa'},
          {name: 'valor_demais_despesas', label: 'Vl. Demais Despesas'},
          {name: 'valor_custas_distribuicao', label: 'Vl. Distribuição'}
        ],
        key: 'id'
      },
      tblFavorecido: {
        fields: [
          {name: 'conta_id', label: 'ID', align: 'right'},
          {name: 'favorecido_nome', label: 'Nome', type: 'toupper'},
          {name: 'favorecido_documento', label: 'Documento'},
          {name: 'favorecido_banco', label: 'Banco', align: 'center'},
          {name: 'favorecido_agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'favorecido_tipo_conta', label: 'Tipo de Conta'}
        ],
        key: 'id'
      },
      tblCustas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'statusN', label: 'Status', type: 'text'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Car.', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'pagamento_id', label: 'Pagamento', align: 'right'},
          {name: 'valorSub', label: 'Valor Rest.', type: 'currency'},
          {name: 'valor_desconto', label: 'Desconto', type: 'currency'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'data_fechamento', label: 'Dt. Pag.', type: 'date'},
          {name: 'qtdrecnoagg', label: 'Títulos', align: 'center'}
        ],
        sum: 'valor',
        group: {field: 'data_custa', agg: 'valor'},
        toggle: 'gerar',
        inactive: 'inativo',
        key: 'id',
        actions: [
          {action: this.modalAddFollowUp, icon: 'edit'},
          {action: this.modalAddFollowUp, icon: 'trash'},
          {action: this.modalDetalhes, icon: 'eye'}
        ],
        button: true
      },
      tabelaHistoricos: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'municipio', label: 'Município', align: 'center'},
          {name: 'cartorio__numero', label: 'Cart.', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'Documento', type: 'documento'},
          {name: 'banco', label: 'Banco', align: 'center'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'favorecido_conta', label: 'Conta', align: 'right'},
          {name: 'usuario', label: 'Usuário', type: 'toupper', align: 'center'},
          {name: 'valor', label: 'Valor', type: 'currency'},
          {name: 'ocorrencia', label: 'Ocorrência', align: 'center'}
        ],
        sum: 'valor',
        key: 'id'
      },
      tblFollowUp: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'ocorrencia', label: 'Ocorrência', type: 'tooltip'},
          {name: 'dataGravacao', label: 'Data', type: 'date'},
          {name: 'usario_nome', label: 'Usuário', align: 'center'}
        ],
        key: 'id',
        button: true
      },
      tblDetalhes: {
        fields: [
          {name: 'RECNO', label: 'RECNO', align: 'right'},
          {name: 'REM_PROTOCOLO', label: 'PROTOCOLO', align: 'right'},
          {name: 'REM_CODIGO', label: 'CODIGO REMESSA', type: 'text'},
          {name: 'cedente', label: 'CEDENTE', type: 'text'},
          {name: 'n_titulo', label: 'Nº TÍTULO', align: 'center'},
          {name: 'nosso_numero', label: 'NOSSO NUMERO', align: 'center'},
          {name: 'cartorio', label: 'CARTÓRIO'},
          {name: 'data_retorno', label: 'data_retorno'},
          {name: 'vlr_titulo', label: 'VLR TÍTULO', align: 'center'},
          {name: 'vlr_custas', label: 'VLR CUSTAS', align: 'center'},
          {name: 'vlr_custas_distribuicao', label: 'VLR DIST.', align: 'center'},
          {name: 'vlr_demais_despesas', label: 'VLR DEMAIS DESPESAS', align: 'center'},
          {name: 'status', label: 'status', align: 'center'}
        ],
        key: 'id',
        button: false
      },
      tblPostegadas: {
        fields: [
          {name: 'id', label: 'ID', align: 'right'},
          {name: 'data_custa', label: 'Data', type: 'date'},
          {name: 'apresentante_nome', label: 'Apresentante'},
          {name: 'municipio', label: 'Municipio'},
          {name: 'uf', label: 'UF', align: 'center'},
          {name: 'cartorio_numero', label: 'Car.', align: 'center'},
          {name: 'favorecido', label: 'Favorecido', type: 'toupper'},
          {name: 'documento', label: 'CPF/CNPJ', align: 'center'},
          {name: 'banco', label: 'Banco', align: 'right'},
          {name: 'agencia', label: 'Agência', align: 'right'},
          {name: 'conta_banco', label: 'Conta', align: 'right'},
          {name: 'valor', label: 'Valor', type: 'currency'}
        ],
        toggle: 'gerar'
      },
      frmCustas: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'documento', label: 'Documento', width: 4, type: 'document', onExit: this.getFavorecido},
              {name: 'favorecido', label: 'Favorecido', type: 'text'}
            ]
          },
          {
            fields: [
              {name: 'banco', label: 'Banco', width: 2, type: 'text'},
              {name: 'agencia', label: 'Agência', width: 3, type: 'text'},
              {name: 'favorecido_conta', label: 'Conta', width: 3, type: 'text'},
              {name: 'tipo_conta', label: 'Tipo Conta', type: 'select', values: [{value: 'C', text: 'Corrente'}, {value: 'P', text: 'Poupança'}]}
            ]
          }
        ]
      },
      fPesquisa: {
        fields: [
          {name: 'data_Pesquisa', label: 'Data Inicial', type: 'data', width: 1},
          {name: 'data_PesquisaF', label: 'Data Final', type: 'dataF', width: 1},
          {
            name: 'apresentante_Pesquisa_id',
            label: 'Apresentante',
            width: 2,
            type: 'select',
            values: []
          },
          {
            name: 'UF_Pesquisa',
            label: 'Estado',
            placeholder: 'UF',
            type: 'select',
            width: 1,
            values: [
              {value: '0', text: 'Todos'},
              {value: 'AC', text: 'AC'},
              {value: 'AL', text: 'AL'},
              {value: 'AP', text: 'AP'},
              {value: 'AM', text: 'AM'},
              {value: 'BA', text: 'BA'},
              {value: 'CE', text: 'CE'},
              {value: 'DF', text: 'DF'},
              {value: 'ES', text: 'ES'},
              {value: 'GO', text: 'GO'},
              {value: 'MA', text: 'MA'},
              {value: 'MT', text: 'MT'},
              {value: 'MS', text: 'MS'},
              {value: 'MG', text: 'MG'},
              {value: 'PA', text: 'PA'},
              {value: 'PB', text: 'PB'},
              {value: 'PR', text: 'PR'},
              {value: 'PE', text: 'PE'},
              {value: 'PI', text: 'PI'},
              {value: 'RJ', text: 'RJ'},
              {value: 'RN', text: 'RN'},
              {value: 'RS', text: 'RS'},
              {value: 'RO', text: 'RO'},
              {value: 'RR', text: 'RR'},
              {value: 'SC', text: 'SC'},
              {value: 'SP', text: 'SP'},
              {value: 'SE', text: 'SE'},
              {value: 'TO', text: 'TO'}
            ]
          },
          {
            name: 'Status',
            label: 'Status da Custa',
            placeholder: 'Status',
            type: 'select',
            width: 2,
            values: [
              {value: "'C', 'V', 'G', 'W', 'X', 'R', 'P'", text: 'Todos'},
              {value: "'C','P'", text: 'Pendentes'},
              {value: 'G', text: 'Pagas - Finalizadas'},
              {value: "'W','X'", text: 'Excluidas'},
              {value: 'T', text: 'Custas Postergadas'}
            ]
          },
          {name: 'valor', label: 'Valor da custa', width: 3, type: 'moeda', disable: true},
          {name: 'documento', label: 'Documento', placeholder: 'CPF/CNPJ', type: 'document', width: 2}
        ],
        button: {label: 'Localizar', side: 'right', action: this.localizar}
      },
      frmTrazerCustas: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'data_pg', label: 'Pagar em', type: 'text', disable: true},
              {name: 'data_custa_trazer', label: 'Data Inicial', type: 'data', width: 3},
              {name: 'data_custa_trazer_final', label: 'Data Final', type: 'data', width: 3},
              {
                name: 'uf',
                label: 'UF',
                placeholder: 'texto',
                type: 'select',
                width: 2,
                onExit: this.getComarcas,
                values: [
                  {value: 'AC', text: 'AC'},
                  {value: 'AL', text: 'AL'},
                  {value: 'AM', text: 'AM'},
                  {value: 'AP', text: 'AP'},
                  {value: 'BA', text: 'BA'},
                  {value: 'CE', text: 'CE'},
                  {value: 'DF', text: 'DF'},
                  {value: 'ES', text: 'ES'},
                  {value: 'GO', text: 'GO'},
                  {value: 'MA', text: 'MA'},
                  {value: 'MG', text: 'MG'},
                  {value: 'MS', text: 'MS'},
                  {value: 'MT', text: 'MT'},
                  {value: 'PA', text: 'PA'},
                  {value: 'PB', text: 'PB'},
                  {value: 'PE', text: 'PE'},
                  {value: 'PI', text: 'PI'},
                  {value: 'PR', text: 'PR'},
                  {value: 'RJ', text: 'RJ'},
                  {value: 'RN', text: 'RN'},
                  {value: 'RO', text: 'RO'},
                  {value: 'RR', text: 'RR'},
                  {value: 'RS', text: 'RS'},
                  {value: 'SC', text: 'SC'},
                  {value: 'SE', text: 'SE'},
                  {value: 'SP', text: 'SP'},
                  {value: 'TO', text: 'TO'}]
              },
              {
                name: 'apresentante_id',
                label: 'Apresentante',
                width: 4,
                type: 'select',
                values: []
              },
              {
                name: 'comarca_id',
                label: 'Comarca',
                width: 6,
                type: 'select',
                values: []
              },
              {name: 'tipo_arq', label: 'Tipo do Arquivo', width: 3, type: 'select', values: [{value: 'R', text: 'Retorno'}, {value: 'C', text: 'Confirmação'}]}
            ]
          }
        ]
      },
      frmFollowUp: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 3, type: 'label'},
              {name: 'valor', label: 'Valor da custa', width: 3, type: 'moeda_custa', disable: true},
              {name: 'valor_subtrair', label: 'Subtrair', type: 'moeda_custa', onExit: this.subtrair},
              {name: 'NovoValor', label: 'Novo Valor', type: 'moeda_custa', disable: true}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Ocorrência', type: 'textarea'}
            ]
          }
        ]
      },
      frmFollowUpDeletar: {
        data: {},
        row: {},
        levels: [
          {
            fields: [
              {name: 'usuariologado', label: 'Usuário Logado', width: 3, type: 'label'}
            ]
          },
          {
            fields: [
              {name: 'ocorrencia', label: 'Motivo', type: 'textarea'}
            ]
          }
        ]
      },
      modal: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        save: this.salvar_conta
      },
      modalFollowUp: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        save: this.salvar_FollowUp,
        delete: this.delete
      },
      modalFollowUpDeletar: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        save: this.salvar_FollowUpDeletar
      },
      modalDetalhes: {
        active: false,
        width: 'large',
        cancel: this.cancel
      },
      modalTrazerCustas: {
        active: false,
        width: 'large',
        cancel: this.cancel,
        trazer: this.trazer_custa
      },
      modalEmolumentosEncontrados: {
        active: false,
        width: 'large',
        cancel: this.cancel
      },
      total_retorno: 0.0,
      total_retorno_desc: 0.0,
      total_desconto: 0.0,
      total_confirmacao: 0.0,
      total_confirmacao_desc: 0.0,
      total_retorno_san: 0.0,
      total_desconto_san: 0.0,
      total_desconto_emp: 0.0,
      total_confirmacao_san: 0.0,
      total_confirmacao_san_desc: 0.0,
      total_confirmacao_emp_desc: 0.0,
      total_retorno_emp: 0.0,
      total_retorno_emp_desc: 0.0,
      total_retorno_emp_desconto: 0.0,
      total_confirmacao_emp: 0.0,
      total_retorno_emp_can: 0.0,
      total_confirmacao_emp_can: 0.0
    }
  },
  created () {
    this.data = utils.lastUtilDay()
    this.data_atual = utils.lastUtilDay()
    this.get_resumo()
    this.getTotalSemDoc()
    this.get_apresentante()
    this.frmTrazerCustas.data.apresentante_id = '001'
    this.frmTrazerCustas.data.uf = 'AC'
    this.frmTrazerCustas.data.tipo_arq = 'R'
    this.frmTrazerCustas.data.data_pg = this.data
    this.frmFollowUp.data.valor_subtrair = '0,00'
    this.getComarcas()
  },
  methods: {
    getComarcas () {
      this.frmTrazerCustas.levels[0].fields[5].values = []
      var self = this
      axios.get(`api/v1/batimento/comarcas/filtro/${this.frmTrazerCustas.data.uf}`).then((res) => {
        self.frmTrazerCustas.levels[0].fields[5].values.push({value: '0', text: 'Todos'})
        for (var i in res.data) {
          self.frmTrazerCustas.levels[0].fields[5].values.push({value: res.data[i].CODIBGE, text: res.data[i].CODIBGE + ' - ' + res.data[i].MUNICIPIO})
        }
      })
      this.frmTrazerCustas.data.comarca_id = '0'
    },
    setTab (idx) {
      this.tabMovimento = idx
      this.tipoPG = idx
      this.set_tab(0)
    },
    setTabEmolumentos (idx) {
      this.tabMovimentoEmolumento = idx
    },
    adiarTodos: function () {
      console.log(this.adiarTudo)
      for (var i = 0; i < this.resumo.length; i++) {
        this.resumo[i]['checado'] = !this.adiarTudo
      }
    },
    adiarTodosS: function () {
      console.log(this.adiarTudo)
      for (var i = 0; i < this.resumo_santander.length; i++) {
        this.resumo_santander[i]['checado'] = !this.adiarTudo
      }
    },
    adiarTodosE: function () {
      console.log(this.adiarTudo)
      for (var i = 0; i < this.resumo_empresas.length; i++) {
        this.resumo_empresas[i]['checado'] = !this.adiarTudo
      }
    },
    adiarTodosC: function () {
      console.log(this.adiarTudo)
      for (var i = 0; i < this.resumo_empresas_canceladas.length; i++) {
        this.resumo_empresas_canceladas[i]['checado'] = !this.adiarTudo
      }
    },
    addAdiar: function () {
      this.showLoading()
      /* eslint-disable */
      if (this.tabMovimento == '1') {
        axios.put(`api/v1/batimento/custas/adiar/espelho/${this.data}`, this.resumo).then((res) => {
          this.get_resumo()
          this.success(res.data)
          this.adiarTudo = false
        }).catch((err) => {
          this.error(err.response.data)
        })
      } else if (this.tabMovimento == '2') {
        axios.put(`api/v1/batimento/custas/adiar/espelho/${this.data}`, this.resumo_santander).then((res) => {
          this.get_resumo()
          this.adiarTudo = false
          this.success(res.data)
        }).catch((err) => {
          this.error(err.response.data)
        })
      } else if (this.tabMovimento == '3') {
        axios.put(`api/v1/batimento/custas/adiar/espelho/${this.data}`, this.resumo_empresas).then((res) => {
          this.get_resumo()
          this.adiarTudo = false
          this.success(res.data)
        }).catch((err) => {
          this.error(err.response.data)
        })
      } else if (this.tabMovimento == '4') {
        axios.put(`api/v1/batimento/custas/adiar/espelho/${this.data}`, this.resumo_empresas_canceladas).then((res) => {
          this.get_resumo()
          this.adiarTudo = false
          this.success(res.data)
        }).catch((err) => {
          this.error(err.response.data)
        })
      }
    },
    localizar (arg) {
      this.buttonrelUfCusta = false
      this.showLoading()
      var data = arg.data
      var dataF = arg.dataF
      this.fPesquisa.fields[0].value = arg.data
      this.fPesquisa.fields[1].value = arg.dataF
      this.fPesquisa.fields[2].value = arg.apresentante_Pesquisa_id
      this.fPesquisa.fields[3].value = arg.UF_Pesquisa
      this.fPesquisa.fields[4].value = arg.valor
      if (arg.documento !== undefined) {
        arg.documento = utils.documentoNumero(arg.documento)
      } else {
        arg.documento = '0'
      }
      this.fPesquisa.fields[5].value = arg.documento
      if ((arg.valor === undefined) || (arg.valor === '')) {
        arg.valor = '0'
      }
      if ((dataF === undefined) || (data === '') || dataF === '') {
        this.error('Preencha os dois campos Data')
      } else if (arg.apresentante_Pesquisa_id === undefined) {
        console.log(arg.apresentante_Pesquisa_id)
        this.error('Informe o apresentante')
      } else if (arg.UF_Pesquisa === undefined) {
        console.log(arg.UF_Pesquisa)
        this.error('Informe o estado')
      } else {
        this.custas = []
        axios.post(`api/v1/batimento/custas/localizar`, arg).then((res) => {
          if (res.data === '0') {
            this.error('Não existe custas para esse filtro')
            this.buttonrelPesquisa = false
          } else {
            this.subtitulo = `Custas encontradas`
            this.tab = 1
            this.custas = res.data
            for (var i = 0; i < res.data.length; i++) {
              switch (res.data[i]['tp_ocorrencia']) {
                case '2':
                  this.custas[i]['tp_ocorrencia'] = 'Protestado'
                  break
                case '3':
                  this.custas[i]['tp_ocorrencia'] = 'Retirada'
                  break
                default:
                  this.custas[i]['tp_ocorrencia'] = 'Carregado'
              }
            }
            this.buttonrelPesquisa = true
          }
          this.closeLoading()
        }).catch((err) => {
          this.error(err.response.data)
        })
      }
    },
    subtrair () {
      var valSubtrair1 = this.frmFollowUp.data.valor_subtrair
      var valSub = parseFloat(this.retirarMascara(valSubtrair1))
      var valAtual = this.frmFollowUp.data.valor
      var valAtualSemM = parseFloat(this.retirarMascara(valAtual))
      var valorSubtraido = parseFloat(valAtualSemM) - parseFloat(valSub)
      var valorFinal = ''
      if (valorSubtraido.toFixed(2) < 0) {
        valorFinal = '-' + this.aplicaMascara(String(valorSubtraido.toFixed(2)))
      } else {
        valorFinal = this.aplicaMascara(String(valorSubtraido.toFixed(2)))
      }
      this.$set(this.frmFollowUp.data, 'valor_subtrair', this.aplicaMascara(valSubtrair1))
      this.$set(this.frmFollowUp.data, 'NovoValor', valorFinal)
    },
    get_resumo () {
      this.adiarTudo = false
      this.buttonrelPesquisa = false
      this.buttonrelUfCusta = false
      this.loading = true
      this.resumo = []
      this.resumo_santander = []
      this.resumo_empresas = []
      this.resumo_empresas_canceladas = []
      axios.get(`api/v1/batimento/custas/${this.data}`).then((res) => {
        if (Object.keys(res.data).length === 0) {
          console.log('sem dados banco')
        } else {
          console.log(res.data)
          this.monta_resumo(res.data)
          this.adiarTudo = false
        }
        axios.get(`api/v1/batimento/custas/Santander/${this.data}`).then((res) => {
          if (Object.keys(res.data).length === 0) {
            console.log('sem dados santander')
          } else {
            this.monta_resumo_santander(res.data)
            this.adiarTudo = false
          }
        }).catch((err) => {
        })
        axios.get(`api/v1/batimento/custas/Empresas/${this.data}`).then((res) => {
          if (Object.keys(res.data).length === 0) {
            console.log('sem dados empresa')
          } else {
            this.monta_resumo_empresas(res.data)
            console.log(res.data)
            this.adiarTudo = false
          }
        }).catch((err) => {
        })
        axios.get(`api/v1/batimento/custas/Empresas/Canceladas/${this.data}`).then((res) => {
          if (Object.keys(res.data).length === 0) {
            console.log('sem dados empresa cancelada')
          } else {
            this.monta_resumo_empresas_canceladas(res.data)
            this.adiarTudo = false
          }
          this.loading = false
        }).catch((err) => {
        })
      }).catch((err) => {
      })
      this.getTotalSemDoc()
    },
    get_apresentante () {
      axios.get(`api/v1/batimento/apresentantes/filtro/`).then((res) => {
        for (var i in res.data) {
          this.frmTrazerCustas.levels[0].fields[4].values.push({value: res.data[i].codigo, text: res.data[i].codigo + ' - ' + res.data[i].nome})
          this.fPesquisa.fields[2].values.push({value: res.data[i].codigo, text: res.data[i].nome + ' - ' + res.data[i].codigo})
        }
        this.fPesquisa.fields[2].values['0'].selected = 'true'
      })
    },
    relatorioPdf () {
      this.showLoading()
      var dt = this.data
      var tipo = this.tabMovimento
      axios.get(`api/v1/batimento/custas/relatorio/geral/pdf/${dt}/${tipo}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioEspelhoPdf () {
      this.showLoading()
      var dt = this.data
      var tipo = this.tabMovimento
      axios.get(`api/v1/batimento/custas/relatorio/espelho/pdf/${dt}/${tipo}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioDescontoPdf () {
      this.showLoading()
      var dt = this.data
      var tipo = this.tabMovimento
      axios.get(`api/v1/batimento/custas/relatorio/descontos/pdf/${dt}/${tipo}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioUFPdf () {
      axios.patch('api/v1/batimento/custas/relatorio/geral/uf/pdf', this.getIds(this.custas)).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    modalDeletarCustas () {
      this.toggle_modalFollowUpDeletar()
    },
    relatorioPesquisaUFPdf () {
      this.showLoading()
      var valor = '0'
      var doc = '0'
      if (this.fPesquisa.fields[4].value === undefined) {
        valor = '0'
      } else {
        valor = this.fPesquisa.fields[4].value
      }
      var dtI = this.fPesquisa.fields[0].value
      var dtF = this.fPesquisa.fields[1].value
      var codApre = this.fPesquisa.fields[2].value
      var uf = this.fPesquisa.fields[3].value
      if (this.fPesquisa.fields[5].value === undefined) {
        doc = '0'
      } else {
        doc = this.fPesquisa.fields[5].value
      }
      axios.get(`api/v1/batimento/custas/relatorio/pesquisa/pdf/${dtI}/${dtF}/${codApre}/${uf}/${valor}/${doc}`).then((res) => {
        var uriContent = 'data:application/pdf,' + escape(res.data)
        var link = document.createElement('a')
        link.href = uriContent
        link.download = 'Rel_Pagamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    relatorioXlsx () {
        this.showLoading()
        var dt = this.data

        axios.get(`api/v1/batimento/relatorios/custas/${dt}`,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${dt}-CUSTAS.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.closeLoading();
            })
            .catch((error) => console.log(error));

    },
    modalAddFollowUp (idx) {
      this.get_followup(this.custas[idx].id)
      this.frmFollowUp.data.idCusta = this.custas[idx].id
      this.$set(this.frmFollowUp.data, 'valor', this.aplicaMascara(this.custas[idx].valor))
      this.$set(this.frmFollowUp.data, 'NovoValor', this.aplicaMascara(this.custas[idx].valor))
      this.frmFollowUp.data.tela = 'C'
      this.toggle_modalFollowUp()
    },
    modalDetalhes (idx) {
      //this.get_followup(this.custas[idx].id)
      this.get_detalhes(this.custas[idx].id)
    },
    retirarMascara (n) {
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1.$2') // coloca virgula antes dos ultimos 2 digitos
      return v
    },
    aplicaMascara (n) {
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      v = v.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      v = v.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      v = v.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      return v
    },
    monta_resumo_empresas (res) {
      this.total_retorno_emp = 0.0
      this.total_retorno_emp_desc = 0.0
      this.total_retorno_emp_desconto = 0.0
      this.total_confirmacao_emp_desc = 0.0
      this.total_confirmacao_emp = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      var totalRetornoDesc = 0.0
      var totalRetornoDesconto = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          reg['retorno_total_desc'] = totalRetornoDesc
          reg['retorno_total_desconto'] = totalRetornoDesconto
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetornoDesc = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          obj['checado'] = false
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            if (typeof res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc === 'undefined')
            {
              obj['retorno_total_desc'] = 0.0
            }
            else
            {
              obj['retorno_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc
            }
            obj['retorno_total_desconto'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].desconto
            totalRetorno += Number(obj['retorno_total'])
            totalRetornoDesc += Number(obj['retorno_total_desc'])
            totalRetornoDesconto += Number(obj['retorno_total_desconto'])
            this.total_retorno_emp += Number(obj['retorno_total'])
            this.total_retorno_emp_desc += Number(obj['retorno_total_desc'])
            this.total_retorno_emp_desconto += Number(obj['retorno_total_desconto'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            obj['confirmacao_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total_desc
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_emp += Number(obj['confirmacao_total'])
            this.total_confirmacao_emp_desc += Number(obj['confirmacao_total_desc'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['retorno_total_desc'] = totalRetornoDesc
      reg['retorno_total_desconto'] = totalRetornoDesconto
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo_empresas = list
    },
    monta_resumo_santander (res) {
      this.total_retorno_san = 0.0
      this.total_retorno_san_desc = 0.0
      this.total_retorno_san_desconto = 0.0
      this.total_confirmacao_san_desc = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      var totalRetornoDesc = 0.0
      var totalRetornoDesconto = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          reg['retorno_total_desc'] = totalRetornoDesc
          reg['retorno_total_desconto'] = totalRetornoDesconto
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          obj['checado'] = false
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total

            if (typeof res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc === 'undefined')
            {
              obj['retorno_total_desc'] = 0.0
            }
            else
            {
              obj['retorno_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc
            }

            obj['retorno_total_desconto'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].desconto
            totalRetorno += Number(obj['retorno_total'])
            totalRetornoDesc += Number(obj['retorno_total_desc'])
            totalRetornoDesconto += Number(obj['retorno_total_desconto'])
            this.total_retorno_san += Number(obj['retorno_total'])
            this.total_retorno_san_desc += Number(obj['retorno_total_desc'])
            this.total_retorno_san_desconto += Number(obj['retorno_total_desconto'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            obj['confirmacao_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total_desc
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_san += Number(obj['confirmacao_total'])
            this.total_confirmacao_san_desc += Number(obj['confirmacao_total_desc'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['retorno_total_desc'] = totalRetornoDesc
      reg['retorno_total_desconto'] = totalRetornoDesconto
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo_santander = list
    },
    monta_resumo_empresas_canceladas (res) {
      this.total_retorno_emp_can = 0.0
      this.total_confirmacao_emp_can = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          obj['checado'] = false
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            totalRetorno += Number(obj['retorno_total'])
            this.total_retorno_emp_can += Number(obj['retorno_total'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_emp_can += Number(obj['confirmacao_total'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo_empresas_canceladas = list
    },
    monta_resumo (res) {
      this.total_retorno = 0.0
      this.total_desconto = 0.0
      this.total_retorno_desc = 0.0
      this.total_confirmacao = 0.0
      this.total_confirmacao_desc = 0.0
      var list = []
      var apreKeys = Object.keys(res)
      var apresentante = apreKeys[0]
      var codigo = res[apreKeys[0]].codigo
      var totalConfirmacao = 0.0
      var totalRetorno = 0.0
      var totalRetornoDesc = 0.0
      var totalDesconto = 0.0
      for (var i in apreKeys) {
        if (codigo !== res[apreKeys[i]].codigo) {
          var reg = {}
          reg['grupo'] = true
          reg['apresentante'] = apresentante
          reg['apresentante_codigo'] = codigo
          reg['retorno_total'] = totalRetorno
          reg['retorno_total_desconto'] = totalDesconto
          reg['retorno_total_desc'] = totalRetornoDesc
          reg['confirmacao_total'] = totalConfirmacao
          apresentante = apreKeys[i]
          codigo = res[apreKeys[i]].codigo
          totalConfirmacao = 0.0
          totalRetorno = 0.0
          totalRetornoDesc = 0.0
          totalDesconto = 0.0
          list.push(reg)
        }
        var ufKeys = Object.keys(res[apreKeys[i]].ufs)
        for (var j in ufKeys) {
          var obj = {}
          obj['grupo'] = false
          obj['apresentante'] = apreKeys[i]
          obj['apresentante_codigo'] = res[apreKeys[i]].codigo
          obj['uf'] = ufKeys[j]
          obj['total'] = Number(res[apreKeys[i]].ufs[ufKeys[j]].total)
          obj['retorno_qtd'] = ''
          obj['retorno_pendente'] = ''
          obj['retorno_gerados'] = ''
          obj['retorno_total'] = ''
          obj['confirmacao_qtd'] = ''
          obj['confirmacao_pendente'] = ''
          obj['confirmacao_gerados'] = ''
          obj['confirmacao_total'] = ''
          obj['checado'] = false
          if ('R' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['retorno_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].quantidade
            obj['retorno_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].pendentes
            obj['retorno_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].gerados
            obj['retorno_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total
            obj['retorno_total_desconto'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].desconto
            obj['retorno_desconto'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].desconto
            if (typeof res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc === 'undefined')
            {
              obj['retorno_total_desc'] = 0.0
            }
            else
            {
              obj['retorno_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['R'].total_desc
            }

            totalRetorno += Number(obj['retorno_total'])
            totalDesconto += Number(obj['retorno_total_desconto'])
            totalRetornoDesc += Number(obj['retorno_total_desc'])
            this.total_retorno += Number(obj['retorno_total'])
            this.total_desconto += Number(obj['retorno_total_desconto'])
            this.total_retorno_desc += Number(obj['retorno_total_desc'])
          }
          if ('C' in res[apreKeys[i]].ufs[ufKeys[j]]) {
            obj['confirmacao_qtd'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].quantidade
            obj['confirmacao_pendente'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].pendentes
            obj['confirmacao_gerados'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].gerados
            obj['confirmacao_total'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total
            obj['confirmacao_total_desc'] = res[apreKeys[i]].ufs[ufKeys[j]]['C'].total_desc
            totalConfirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao += Number(obj['confirmacao_total'])
            this.total_confirmacao_desc += Number(obj['confirmacao_total_desc'])
          }
          list.push(obj)
        }
      }
      reg = {}
      reg['grupo'] = true
      reg['apresentante'] = apresentante
      reg['apresentante_codigo'] = codigo
      reg['retorno_total'] = totalRetorno
      reg['retorno_total_desc'] = totalRetornoDesc
      reg['retorno_total_desconto'] = totalDesconto
      reg['confirmacao_total'] = totalConfirmacao
      list.push(reg)
      this.resumo = list
    },
    load () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/${this.data}`).then((res) => {
        this.get_resumo()
        this.adiarTudo = false
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getTotalSemDoc () {
      axios.get(`api/v1/batimento/custas/total_sem_documento/${this.data}`).then((res) => {
        this.totalSemDoc = res.data
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getModalTrazerCustas () {
      this.toggle_modalTrazerCustas()
    },
    getCustasSemDocumento () {
      this.showLoading()
      axios.get(`api/v1/batimento/custas/sem_documento/${this.data}`).then((res) => {
        this.subtitulo = `Custas sem Documento`
        this.tab = 1
        this.custas = res.data
        for (var i = 0; i < res.data.length; i++) {
          switch (res.data[i]['tp_ocorrencia']) {
            case '2':
              this.custas[i]['tp_ocorrencia'] = 'Protestado'
              break
            case '3':
              this.custas[i]['tp_ocorrencia'] = 'Retirada'
              break
            default:
              this.custas[i]['tp_ocorrencia'] = 'Carregado'
          }
        }
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getPostergados () {
      this.showLoading()
      axios.get(`api/v1/batimento/custas/postergados`).then((res) => {
        this.postergados = res.data
        this.tab = 2
        this.closeLoading()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    get_custas_apresentante_uf (idx, tipo) {
      this.buttonrelUfCusta = true
      this.buttonrelPesquisa = false
      if (this.tipoPG === '1') {
        this.selected = this.resumo[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo[idx].apresentante
        this.filter.uf = this.resumo[idx].uf
      } if (this.tipoPG === '2') {
        this.selected = this.resumo_santander[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo_santander[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo_santander[idx].apresentante
        this.filter.uf = this.resumo_santander[idx].uf
      } if (this.tipoPG === '3') {
        this.selected = this.resumo_empresas[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo_empresas[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo_empresas[idx].apresentante
        this.filter.uf = this.resumo_empresas[idx].uf
      } if (this.tipoPG === '4') {
        this.selected = this.resumo_empresas_canceladas[idx]
        this.selected.tipo = tipo
        this.filter.apresentante = this.resumo_empresas_canceladas[idx].apresentante_codigo
        this.filter.apresentante_nome = this.resumo_empresas_canceladas[idx].apresentante
        this.filter.uf = this.resumo_empresas_canceladas[idx].uf
      }
      this.filter.tipo_arquivo = tipo
      this.get_custas()
    },
    get_custas () {
      axios.get(`api/v1/batimento/custas/${this.data}/${this.filter.apresentante}/${this.filter.uf}/${this.filter.tipo_arquivo}/${this.tipoPG}`).then((res) => {
        this.subtitulo = `${this.selected.apresentante} - ${this.selected.uf} - ${utils.tipo_arquivo[this.selected.tipo]}`
        this.tab = 1
        this.custas = res.data
        console.log(res.data)
        for (var i = 0; i < res.data.length; i++) {
          switch (res.data[i]['tp_ocorrencia']) {
            case '2':
              this.custas[i]['tp_ocorrencia'] = 'Protestado'
              break
            case '3':
              this.custas[i]['tp_ocorrencia'] = 'Retirada'
              break
            default:
              this.custas[i]['tp_ocorrencia'] = 'Carregado'
          }
        }
      })
    },
    get_followup (idx) {
      axios.get(`api/v1/batimento/custas/followup/${idx}`).then((res) => {
        this.followup = res.data
      })
    },
    get_detalhes (idx) {
      axios.get(`api/v1/batimento/custas/detalhes/${idx}`).then((res) => {
        this.detalhes = res.data
        if(res.data == 0){
          this.error('Sem dados para vizualização!')
        }else{
            this.toggle_modalDetalhes()
        }
      })
    },
    gerar_pagamentos () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/pagamentos', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success('Pagamentos gerados com sucesso!')
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    gerarPagamentosDia () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/pagamentos/${this.data}/${this.tipoPG}`).then((res) => {
        this.get_resumo()
        this.adiarTudo = false
        if (res.data === 'Favorecido sem documento, informe o documento do favorecido!') {
          this.success(res.data)
          this.closeLoading()
        } else {
          this.success(`Arquivo ${res.data.arquivo} gerado com sucessso!`)
          this.closeLoading()
        }
      }).catch((err) => {
        this.error(err.response.data)
        this.closeLoading()
      })
    },
    regerarPagamentosDia () {
      this.showLoading()
      axios.put(`api/v1/batimento/custas/pagamentos/regerar/${this.data}/${this.tipoPG}`).then((res) => {
        this.get_resumo()
        this.adiarTudo = false
        if (res.data === 'Favorecido sem documento, informe o documento do favorecido!') {
          this.success(res.data)
        } else {
          this.success(`Arquivo ${res.data.arquivo} gerado com sucessso!`)
        }
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    adiar () {
      this.showLoading()
      axios.patch('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    postergar () {
      this.showLoading()
      axios.post('api/v1/batimento/custas/adiar', this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazer () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    trazerPostergados () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/trazer/${utils.lastUtilDay()}`, this.getIds(this.postergados)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    restaurar () {
      this.showLoading()
      axios.patch(`api/v1/batimento/custas/restaurar`, this.getIds(this.custas)).then((res) => {
        this.get_custas()
        this.success(res.data)
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getIds (lista) {
      var ids = []
      for (var i in lista) {
        if (lista[i].gerar) {
          ids.push(lista[i].id)
        }
      }
      return ids
    },
    set_tab (n) {
      if (n === 0) {
        this.get_resumo()
        this.adiarTudo = false
      }
      this.tab = n
      this.custa = []
    },
    toggle_gerar (idx) {
      this.custas[idx].gerar = !this.custas[idx].gerar
    },
    toggle_modal () {
      this.modal.active = !this.modal.active
    },
    toggle_modalFollowUp () {
      this.modalFollowUp.active = !this.modalFollowUp.active
    },
    toggle_modalFollowUpDeletar () {
      this.modalFollowUpDeletar.active = !this.modalFollowUpDeletar.active
    },
    toggle_modalDetalhes () {
      this.modalDetalhes.active = !this.modalDetalhes.active
    },
    toggle_modalTrazerCustas () {
      this.modalTrazerCustas.active = !this.modalTrazerCustas.active
    },
    toggle_modalEmolumentosEncontrados () {
      this.modalEmolumentosEncontrados.active = !this.modalEmolumentosEncontrados.active
    },
    nova_conta (idx) {
      if ((this.custas[idx].favorecido === null) || (this.custas[idx].favorecido === '')) {
        this.toggle_modal()
        this.frmCustas.data.id = this.custas[idx].id
        this.getHistorico(this.custas[idx].uf, this.custas[idx].apresentante_nome, this.custas[idx].cartorio_numero)
      }
    },
    novoFollowUp (idx) {
      axios.get(`api/v1/batimento/custas/followup/${this.custas[idx].id}`).then((res) => {
        this.followup = res.data
      })
      this.toggle_modalFollowUp()
      this.frmFollowUp.data.idCusta = this.custas[idx].id
      this.$set(this.frmFollowUp.data, 'valor_subtrair', '0,00')
    },
    salvar_conta () {
      var banco = this.frmCustas.data.banco
      var ttbanco = banco.trim()
      var tbanco = ttbanco.length
      var agencia = this.frmCustas.data.agencia
      var tagencia = agencia.indexOf('\t')
      var esagencia = agencia.indexOf(' ')
      var conta = this.frmCustas.data.favorecido_conta
      var tconta = conta.indexOf('\t')
      var esconta = conta.indexOf(' ')
      if (tbanco < 3) {
        alert('Campo banco tem que conter 3 dígitos')
        document.getElementById('banco').focus()
      } else if ((tagencia !== -1) || (esagencia !== -1)) {
        alert('Não é permitido espaços em branco para agência')
        document.getElementById('agencia').focus()
      } else if ((tconta !== -1) || (esconta !== -1)) {
        alert('Não é permitido espaços em branco para conta')
        document.getElementById('favorecido_conta').focus()
      } else {
        axios.post(`api/v1/batimento/custas/conta`, this.frmCustas.data).then((the) => {
          this.toggle_modal()
          this.frmCustas.data = {}
          this.get_custas()
          this.getTotalSemDoc()
          this.getCustasSemDocumento()
          this.modal.active = false
        })
      }
    },
    trazer_custa () {
      this.showLoading()
      var ok = 'True'
      if (this.frmTrazerCustas.data.data_custa_trazer === undefined || this.frmTrazerCustas.data.data_custa_trazer === '') {
        alert('Selecione a Data Inicial')
        ok = 'false'
        this.closeLoading()
      }
      if (this.frmTrazerCustas.data.data_custa_trazer_final === undefined || this.frmTrazerCustas.data.data_custa_trazer_final === '') {
        alert('Selecione a Data Final')
        ok = 'false'
        this.closeLoading()
      }
      if (this.frmTrazerCustas.data.apresentante_id === undefined || this.frmTrazerCustas.data.apresentante_id === '') {
        alert('Selecione o apresentatne')
        ok = 'false'
        this.closeLoading()
      }
      if (this.frmTrazerCustas.data.uf === undefined || this.frmTrazerCustas.data.uf === '') {
        alert('Selecione o Estado')
        ok = 'false'
        this.closeLoading()
      }
      if (this.frmTrazerCustas.data.tipo_arq === undefined || this.frmTrazerCustas.data.tipo_arq === '') {
        alert('Selecione o tipo do Arquivo')
        ok = 'false'
        this.closeLoading()
      }
      if (this.frmTrazerCustas.data.comarca_id === undefined || this.frmTrazerCustas.data.comarca_id === '') {
        alert('Selecione a comarca')
        ok = 'false'
        this.closeLoading()
      }

      if (ok === 'True') {
        axios.post(`api/v1/batimento/custas/trazer/nacional/`, this.frmTrazerCustas.data).then((res) => {
          this.dadosEmolumentosEncontrados = {}
          this.get_resumo()
          this.getTotalSemDoc()
          this.toggle_modalTrazerCustas()
          this.toggle_modalEmolumentosEncontrados()
          this.dadosEmolumentosEncontrados = {
            nome_apresentante: res.data.nome_apresentante,
            uf: res.data.uf,
            data_custa: res.data.data_custa,
            total: res.data.total,
            total_novos : res.data.total_novos,
            total_pagos : res.data.total_pagos,
            emolumentos_novos : res.data.emolumentos_novos,
            emolumentos_pagos : res.data.emolumentos_pagos                      
          }
        }).catch((err) => {
          this.dadosEmolumentosEncontrados = {}
          this.error(err.response.data.mensagem)
        })
      } else {
        this.closeLoading()
      }
    },
    salvar_FollowUp () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.valor_subtrair === '') {
        this.frmFollowUp.data.valor_subtrair = '0,00'
      }
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha a Ocorrência')
      } else {
        console.log(parseInt(this.frmFollowUp.data.NovoValor))
        var inteiro = this.frmFollowUp.data.NovoValor
        var negativo = inteiro.indexOf('-')
        if (negativo === 0) {
          this.$set(this.frmFollowUp.data, 'valor_subtrair', '0,00')
          alert('A Custa não pode ter valor Negativo')
        } else {
          axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
            this.toggle_modalFollowUp()
            this.frmFollowUp.data = {}
            this.get_custas()
            this.modalFollowUp.active = false
          })
        }
      }
    },
    salvar_FollowUpDeletar () {
      var ocorrencia = this.frmFollowUpDeletar.data.ocorrencia
      if (this.frmFollowUpDeletar.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        this.error('Preencha o motivo!')
      } else {
        this.frmFollowUpDeletar.data.ids = this.getIds(this.custas)
        axios.post(`api/v1/batimento/custas/deletar/all`, this.frmFollowUpDeletar.data).then((res) => {
          console.log(res.data[0])
          if (res.data[0] === 'ok') {
            this.toggle_modalFollowUpDeletar()
            this.frmFollowUpDeletar.data = {}
            this.get_custas()
            this.modalFollowUpDeletar.active = false
            this.success('Deletado com sucesso!')
          } else {
            this.error('Não foi possivel deletar - Verifique o status da custa')
          }
        })        
      }
    },
    delete () {
      var ocorrencia = this.frmFollowUp.data.ocorrencia
      if (this.frmFollowUp.data.valor_subtrair === '' || this.frmFollowUp.data.valor_subtrair === undefined) {
        this.frmFollowUp.data.valor_subtrair = '0,00'
      }
      if (this.frmFollowUp.data.ocorrencia === undefined || ocorrencia.trim() === '') {
        alert('Preencha o Ocorrência')
      } else {
        axios.post(`api/v1/batimento/custas/followup`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.get_custas()
          this.modalFollowUp.active = false
        })

        axios.post(`api/v1/batimento/custas/update-delete`, this.frmFollowUp.data).then((the) => {
          this.toggle_modalFollowUp()
          this.frmFollowUp.data = {}
          this.get_custas()
          this.getCustasSemDocumento()
          this.modalFollowUp.active = false
        })
      }
    },
    cancel () {
      this.frmCustas.data = {}
    },
    getFavorecido () {
      var documento = this.frmCustas.data.documento
      if (utils.validarDocumento(documento) === false) {
        this.error('Documento inválido.')
        this.frmCustas.data.favorecido = ''
        this.frmCustas.data.banco = ''
        this.frmCustas.data.agencia = ''
        this.frmCustas.data.favorecido_conta = ''
        this.frmCustas.data.tipo_conta = ''
        return false
      }
      axios.get(`api/v1/batimento/pagamentos/favorecido/${this.frmCustas.data.documento}`).then((res) => {
        if (res.data.length > 0) {
          this.tabFavorecido = 1
          this.favorecidos = res.data
        } else {
          this.warning('Favorecido não encontrado.')
          this.frmCustas.data.favorecido = ''
          this.frmCustas.data.banco = ''
          this.frmCustas.data.agencia = ''
          this.frmCustas.data.favorecido_conta = ''
          this.frmCustas.data.tipo_conta = ''
        }
      })
    },
    setFavorecido (idx) {
      this.tabFavorecido = 0
      this.frmCustas.data.favorecido = this.favorecidos[idx].favorecido_nome
      this.frmCustas.data.banco = this.favorecidos[idx].favorecido_banco
      this.frmCustas.data.agencia = this.favorecidos[idx].favorecido_agencia
      this.frmCustas.data.favorecido_conta = this.favorecidos[idx].favorecido_conta
      this.frmCustas.data.tipo_conta = this.favorecidos[idx].favorecido_tipo_conta
    },
    excluir () {
      axios.patch(`api/v1/batimento/custas/excluir`, this.getIds(this.postergados)).then((res) => {
        this.success(res.data)
        this.getPostergados()
      }).catch((err) => {
        this.error(err.response.data)
      })
    },
    getHistorico (uf,apresentante_codigo,numero_cartorio) {
      axios.get(`api/v1/batimento/pagamentos/historicos/custa/${uf}/${apresentante_codigo}/${numero_cartorio}`).then((res) => {
        if (res.data.length > 0) {
          this.historicos = res.data
        }
      })
    }
  },
  computed: {
    total_custas () {
      var tot = 0.0
      for (var i in this.custas) {
        if (this.custas[i].gerar === 1) {
          tot += Number(this.custas[i].valor)
        }
      }
      return tot
    }
  }
}
</script>

<style lang="scss">

a.info {
font-family:verdana,arial,helvetica,sans-serif;
position:relative;
border-bottom:1px dashed #ccc;
text-decoration:none;
color:#333333;
}
/* Added to solve the z-order problem of IE */
a.info:hover {
    z-index:2;
}
/* End */
a.info span{
    display: none;
}
a.info:hover span{
 display:block;
 position:absolute;
 z-index:1;
 bottom:1em;
 width:500px !important;
 border:1px solid #F2AF1D;
 background-color:#FDF1D5;
 color:#000;
 text-align:justify;
 font-size:12px;
 font-weight:normal;
 padding:3px;
 line-height:15px;
}
.modal-card-body {
  min-height: 300px !important;
}
.es-loading {
  z-index: 100;
}
.lineAmarelo {
  background: #d5d553 !important;
}
.linedesconto {
  background: #aedff7 !important;
}
.es-table-wrapper .es-table-scroller {
  overflow-x: inherit !important;
}
</style>
