<template>
  <div class="es-filter">
    {{title}}
    <div class="columns is-desktop is-multiline">
      <template v-for="field in config.fields">
        <div class="column" v-if="field.type == 'digito'">
          <div class="field is-small">
            <label> {{ field.label }} </label>
            <div class="control">
              <input class="input" :maxlength="1" :id="field.name" :name="field.name" :type="'text'" :placeholder="field.placeholder" v-model="filter[field.name]" />
            </div>
          </div>
        </div>
        <div class="column" v-if="field.type == 'banco'">
          <div class="field is-small">
            <label> {{ field.label }} </label>
            <div class="control">
              <input class="input" :maxlength="3" :id="field.name" :name="field.name" :type="'text'" :placeholder="field.placeholder" v-model="filter[field.name]" />
            </div>
          </div>
        </div>
        <div class="column is-1" v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" v-else-if="field.type == 'text'">
          <div class="field is-small">
            <label> {{ field.label }} </label>
            <div class="control">
              <input class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" :mask="field.mask" v-model="filter[field.name]" @keyup.enter="execute(config.button.action)"/>
            </div>
          </div>
        </div>

        <div class="column" v-else-if="field.type == 'contaText' && contasDebitosFiltradas.length == 0">
          <div class="field is-small">
            <label> {{ field.label }} </label>
            <div class="control">
              <input class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" :mask="field.mask" v-model="filter[field.name]" @keyup.enter="execute(config.button.action)"/>
            </div>
          </div>
        </div>

        <div class="column" v-else-if="field.type == 'contaSelect' && contasDebitosFiltradas.length !== 0">
          <div class="field is-small">
            <div class="control">
              <label> {{ field.label }}
                <div class="select is-info">
                  <select :id="field.name" :name="field.name" v-model="filter[field.name]" @change="execute(config.select.action)"  >
                    <option v-for="item in field.values" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="column" v-else-if="field.type == 'document'">
          <div class="field is-small">
            <label> {{ field.label }} </label>
            <div class="control">
              <the-mask class="input" :id="field.name" :name="field.name" :type="field.type" :placeholder="field.placeholder" :mask="['###.###.###-##', '##.###.###/####-##']" v-model="filter[field.name]" @keyup.enter="execute(config.button.action)"/>
            </div>
          </div>
        </div>
        <div class="column is-2" v-else-if="field.type == 'moeda'">
          <div class="control">
            <label> {{ field.label }}
              <label class="label">
                <input class="input" v-bind:id="field.name" type="text"  v-model="filter[field.name]" v-on:keyup="aplicaMascara(filter[field.name], field.name)">
              </label>
            </label>
          </div>
        </div>
        <div class="column is-2" v-else-if="field.type == 'data'" v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" >
          <label> {{ field.label }}
            <es-datepicker v-model="data" type="input"></es-datepicker>
          </label>
        </div>
        <div class="column is-2" v-else-if="field.type == 'dataF'" v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" >
          <label> {{ field.label }}
            <es-datepicker v-model="dataF" type="input"></es-datepicker>
          </label>
        </div>
        <div class="column" v-else-if="field.type == 'checkbox'">
          <div class="field is-small">
            <label class="checkbox">
              <input type="checkbox" :name="field.name" :checked="field.checked" :id="field.id" v-model="filter[field.name]">
              {{ field.label }}
            </label>
          </div>
        </div>
        <div class="column is "v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" v-else-if="field.type == 'select'">
          <div class="field is-small">
            <div class="control">
              <label> {{ field.label }}
                <div class="select is-info">
                  <select :id="field.name" :name="field.name" v-model="filter[field.name]" @change="execute(config.select.action)"  >
                    <option v-for="item in field.values" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="column"v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" v-else-if="field.type == 'select1'">
          <div class="field is-small">
            <div class="control">
              <label> {{ field.label }}
                <div class="select is-info">
                  <select :id="field.name" :name="field.name" v-model="filter[field.name]" @change="execute(config.select.action)"  >
                    <option v-for="item in field.values" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="column"v-bind:class="[field.width != 0 ? 'is-' + field.width: 'is-2']" v-else-if="field.type == 'select2'">
          <div class="field is-small">
            <div class="control">
              <label> {{ field.label }}
                <div class="select is-info">
                  <select :id="field.name" :name="field.name" v-model="filter[field.name]" >
                    <option v-for="item in field.values" :value="item.value">{{ item.text }}</option>
                  </select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="column"  v-else-if="field.type == 'radio'">
          <div class="field is-small">
            <div class="control">
              <label> {{ field.label }}
                <label class="radio" v-for="item in field.values">
                  <input type="radio" :name="field.name" :id="item.id" v-model="filter[field.name]">
                  {{ item.text }}
                </label>
              </label>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="column" style="display: flex;justify-content: center;" v-if="(config.button != undefined && config.button != '') && config.button.side == 'right'">
        <div class="field is-small">
          <div class="control">
            <a class="button is-info" style="margin-bottom: inherit;" @click="execute(config.button.action)">{{config.button.label}}<icon name="search" /></a>
          </div>
        </div>
        <div class="field is-small">
          <div class="control">
            <a class="button is-warning" style="margin-bottom: inherit;" @click="execute(config.buttonLimpar.action)">Limpar<icon name="eraser" style="color: #ffffff;" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      filter: {},
      default: '',
      data: '',
      dataF: ''
    }
  },
  props: ['title', 'config','contasDebitosFiltradas'],
  methods: {
    aplicaMascara (n, name) {
      var v = n
      v = v.replace(/\D/g, '') // permite digitar apenas numero
      v = v.replace(/(\d{1})(\d{14})$/, '$1.$2') // coloca ponto antes dos ultimos digitos
      v = v.replace(/(\d{1})(\d{11})$/, '$1.$2') // coloca ponto antes dos ultimos 11 digitos
      v = v.replace(/(\d{1})(\d{8})$/, '$1.$2') // coloca ponto antes dos ultimos 8 digitos
      v = v.replace(/(\d{1})(\d{5})$/, '$1.$2') // coloca ponto antes dos ultimos 5 digitos
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos
      this.filter[name] = v
    },
    execute (action) {
      if (typeof action === 'function') {
        this.filter['data'] = this.data
        this.filter['dataF'] = this.dataF
        action(this.filter)
      } else {
        this.$emit(action, this.filter)
      }
    },
    exec (action, value) {
      this.$emit(action, value)
    },
    relationship (service, value, text, fieldName) {
      this.filter.fields[this.filter.fields.indexOf(fieldName)].values = []
      axios.get(`${service}`).then((res) => {
        var values
        for (var idx in res.data) {
          values = [idx]
          values[idx] = ['value']
          values[idx] = ['text']
          values[idx]['value'] = res.data[idx][value]
          values[idx]['text'] = res.data[idx][text]
          this.filter.fields[this.filter.fields.indexOf(fieldName)].values.push(values[idx])
        }
      })
    }
  }
}
</script>

<style lang="sass">

.es-filter
  background: #eee
  padding: 0em 1em
  border-radius: 3px

</style>

<style>
.column.is-1 {
  flex: 0 0 10.5%;
  max-width: 10.5%;
}
</style>